<template>
  <el-row>
    <el-col>
      <el-form
        id="change"
        status-icon
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        关键词：
        <el-input
          style="width: 200px; margin-right: 10px"
          class="search"
          v-model="keyword"
          prefix-icon="el-icon-search"
          clearable
          placeholder="请输入关键词"
          
        />
        大类别：
        <el-select
          clearable
          v-model="value1"
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in bigOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        中类别：
        <el-select
          clearable
          v-model="value2"
          placeholder="请选择"
          style="margin-right: 10px"
        
        >
          <el-option
            v-for="item in midOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form>
    </el-col>
    <el-col>
      <el-form style="margin-top: 10px">
        年：
        <el-select
          clearable
          v-model="year"
          filterable
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in yearOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        月：
        <el-select
          clearable
          v-model="month"
          filterable
          placeholder="请选择"
          style="margin-right: 10px"
        >
          <el-option
            v-for="item in monthOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="getYouleList()"
          style="background: #1d90ff; border: none"
          >查询</el-button
        >
      </el-form>
    </el-col>

    <el-col>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        border
        @sort-change="changeTableSort"
        :default-sort="{ prop: 'amount', order: 'descending' }"
      >
        <el-table-column
          prop="keyword"
          label="关键词"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="type" label="类型" width="100" align="center">
        </el-table-column>
        <el-table-column
          prop="msrpPrice"
          sortable="custom"
          label="指导价"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pcSearch"
          sortable="custom"
          label="PC搜索量"
          width="110"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobileSearch"
          sortable="custom"
          label="移动搜索量"
          width="115"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pcLevel"
          label="计算机竞争程度"
          width="115"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobileLevel"
          label="移动竞争程度"
          width="115"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="monthShow"
          sortable="custom"
          label="月展示量"
          width="110"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="monthClick"
          sortable="custom"
          label="月点击量"
          width="110"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="pcMsrpPrice"
          sortable="custom"
          label="PC指导价"
          width="110"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobileMsrpPrice"
          sortable="custom"
          label="移动指导价"
          align="center"
          width="115"
        >
        </el-table-column>
        <el-table-column
          prop="year"
          width="110"
          label="年"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="month"
          width="110"
          label="月"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="day"
          label="日"
          align="center"
        >
        </el-table-column>
      </el-table>
    </el-col>
    <el-col align="right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage4"
        :page-sizes="[10, 20, 50, 60]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagetotal"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<script>
import $ from "jquery";

export default {
  methods: {
     createyear() {
      let date = new Date();
      let y = date.getFullYear();
      let sss = {id:y-2, label:(y-2) + '年'};
      this.yearOptions.push(sss);
      let ss = {id:y-1, label:(y-1) + '年'};
      this.yearOptions.push(ss);
      let s = {id:y, label:y + '年'};
      this.yearOptions.push(s);
      let sc = {id:y+1, label:(y+1) + '年'};
      this.yearOptions.push(sc);
      let scc = {id:y+2, label:(y+2) + '年'};
      this.yearOptions.push(scc);
      let sccc = {id:y+3, label:(y+3) + '年'};
      this.yearOptions.push(sccc);
      
        
       
    },
    createDates() {
      for (let i = 1; i <= 12; i++) {
        let date = { id: i, label: i + "月" };
        this.monthOptions.push(date);
      }
    },
    getYouleList(page, record) {
      console.log(this.value1);
      if(this.value1 == null || this.value2 == null){
        this.$message('类别不可为空')
      }else{
        this.$api.get(
        "/keyword/list",
        null,
        {
          page: page === 1 ? 1 : this.page,
          record: record === 10 ? 10 : this.record,
          keyword: this.keyword,
          msrpPrice: this.msrpPrice,
          pcSearch: this.pcSearch,
          mobileSearch: this.mobileSearch,
          monthShow: this.monthShow,
          monthClick: this.monthClick,
          pcMsrpPrice: this.pcMsrpPrice,
          mobileMsrpPrice: this.mobileMsrpPrice,
          bigTypeId: this.value1,
          midTypeId: this.value2,
          year: this.year,
          month: this.month,
        },
        (successRes) => {
          console.log(successRes);
          this.tableData = successRes.data.rows;
          this.pagetotal = successRes.data.total;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );

      }
      
    },
    changeTableSort(column, prop, order) {
      (this.msrpPrice = null),
        (this.pcSearch = null),
        (this.mobileSearch = null),
        (this.monthShow = null),
        (this.monthClick = null),
        (this.pcMsrpPrice = null),
        (this.mobileMsrpPrice = null);
      if (column.prop === "msrpPrice") {
        if (column.order === "ascending") {
          this.msrpPrice = true;
        } else {
          this.msrpPrice = false;
        }
      }
      if (column.prop === "pcSearch") {
        if (column.order === "ascending") {
          this.pcSearch = true;
        } else {
          this.pcSearch = false;
        }
      }
      if (column.prop === "mobileSearch") {
        if (column.order === "ascending") {
          this.mobileSearch = true;
        } else {
          this.mobileSearch = false;
        }
      }
      if (column.prop === "monthShow") {
        if (column.order === "ascending") {
          this.monthShow = true;
        } else {
          this.monthShow = false;
        }
      }
      if (column.prop === "monthClick") {
        if (column.order === "ascending") {
          this.monthClick = true;
        } else {
          this.monthClick = false;
        }
      }
      if (column.prop === "pcMsrpPrice") {
        if (column.order === "ascending") {
          this.pcMsrpPrice = true;
        } else {
          this.pcMsrpPrice = false;
        }
      }
      if (column.prop === "mobileMsrpPrice") {
        if (column.order === "ascending") {
          this.mobileMsrpPrice = true;
        } else {
          this.mobileMsrpPrice = false;
        }
      }
      this.currentPage4 = 1;
      this.getYouleList(1, 10);

      console.log(column.prop); //prop标签 => nickname
      console.log(column.order); //descending降序、ascending升序
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.record = val;
      this.getYouleList(this.page, this.record);
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getYouleList(this.page, this.record);
    },
    getBigTypeList() {
      this.$api.get(
        "/type/big/list",
        null,
        {
          list: false,
        },
        (successRes) => {
          console.log(successRes);
          this.bigOptions = successRes.data.rows;
        },
        (failureRes) => {
          console.log(failureRes);
        }
      );
    },
  },
  data() {
    return {
      yearOptions: [],
      year: "",
      monthOptions: [],
      month: "",
      datasOptions: [],
      datas: "",
      page: 1,
      record: 10,
      keyword: "",
      type: "",
      msrpPrice: null,
      pcSearch: null,
      mobileSearch: null,
      monthShow: null,
      monthClick: null,
      pcMsrpPrice: null,
      mobileMsrpPrice: null,
      bigOptions: [],
      midOptions: [],
      value: "",
      tableData: [],
      asearchdata: {},
      userInfo: {},
      updateStatus: 0,
      pagetotal: 0,
      spagetotal: 10,
      nowpagetotal: 1,
      currentPage4: 1,
      value1: null,
      value2: null,
    };
  },
  watch: {
    value1: {
      handler(newVal, oldVal) {
        this.newVal = newVal;
        console.log(this.newVal);
        this.$api.get(
          "/type/getMidTypeByBigTypeId",
          null,
          {
            list: false,
            bigTypeId: this.newVal,
          },
          (successRes) => {
            console.log(successRes);
            this.midOptions = successRes.data;
            // console.log(" type="+this.midOptions)
          },
          (failureRes) => {
            console.log(failureRes);
          }
        );
      },
    },
  },
  mounted() {
    this.getBigTypeList();
    this.createDates();
    this.createyear();
  },
};
</script>